import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {filter, map} from 'rxjs/operators';
import {LoginStateService} from './login/loginstate.service';
import {Observable} from 'rxjs';
import {IdentityDetails} from '../domain/identity/identityTableData';
import {CompanyDetails, NawDetails} from '../domain/identity/identitydata';
import {LocalDate, DateTimeFormatter} from '@js-joda/core';
import {PEditorComponent} from '../components/p-editor/p-editor.component';

/**
 * File service that uses functionality having to do with MsGraphService
 */
@Injectable({
  providedIn: 'root',
})
export class MetadataService {

  constructor(
      private loginStateService: LoginStateService,
      private translateService: TranslateService
  ) {}

  addMetadata(notes: string, isHtml: boolean): Observable<string> {
    return this.loginStateService.currentUser$.pipe(
      filter((u) => !!u),
      map((u) => {
        const userName = u.user.userIdentity.fullNames.normal;
        const date = LocalDate.now().format(DateTimeFormatter.ofPattern( 'dd-MM-yyyy'));
        const metaData = isHtml ? `<p>${userName}, ${date}: </p>` : `${userName}, ${date}: \n`;

        return notes ? notes.concat(`${isHtml ? '<br/>' : '\n\n'}${metaData}`) : metaData;
      })
    );
  }

  /**
   * Scrolls to the bottom in a p-editor
   */
  scrollToBottomPEditor(editor: PEditorComponent): void {
    if (editor) {
      const interval = setTimeout(() => {
        const quill = editor.editor.getQuill();
        quill.setSelection(quill.getLength());
      }, 1);
    }
  }

  /**
   * Scrolls to the bottom in a text area
   */
  scrollToBottomTextarea(textarea: Element): void {
    if (textarea) {
      const interval = setTimeout(() => {
        textarea.scrollTop = textarea.scrollHeight;

        // When de textarea is scrolled entirely to the bottom
        // (scrollable height - position of actual top of the text area = height of the textarea),
        // the interval should be cleared. Otherwise the element will constantly be scrolled to the bottom
        // and scrolling up won't be possible
        if (textarea.scrollHeight - textarea.scrollTop === textarea.clientHeight) {
          clearInterval(interval);
        }
      }, 1);
    }
  }
}
